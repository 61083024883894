<template>
  <div
    v-if="renderContent.length"
    class="toggle"
  >
    <div class="titleRow">Продолжить просмотр</div>
    <ProgramSwiper
      :programs="renderContent"
      local-storage-key="pausesList"
      :show-pins="true"
    />
  </div>
</template>

<script setup>
import ProgramSwiper from '@/components/ui/Swipers/ProgramSwiper.vue';

const channelsStore = useChannelsListStore();
const pausedStore = usePausedListStore();
const { pausedList } = storeToRefs(pausedStore);

const { user } = storeToRefs(useUserStore());
const show_unsubscribed = computed(() => Number(user.value?.current_profile?.show_unsubscribed) === 1);

const renderContent = computed(() => {
  return pausedList.value.filter(video => {
    if (video.pause_type === 'program') {
      const channel = channelsStore.currentChannel(video.channel_id);
      return !(!channel.id || (!show_unsubscribed.value && channel.locked));
    }
    return true;
  });
});

pausedStore.fetchPausedList();
channelsStore.fetchChannels();
</script>
